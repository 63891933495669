<template>
  <div>
    <div class="contentsWidth mx-auto mt-4">
      <div class="">
        <p class="title mb-0">選考評価 - 一覧</p>
        <hr class="title">
      </div>

      <!-- 担当変更 -->
      <div
        class="my-2"
        v-if="hasZaidanAuth">
        <div class="bold">選考評価担当の切り替え</div>
        <div class="marker-wrap search-middle">
          <b-form-select
            :value="filter.marker"
            @input="changeMarker($event);"
            :options="markerOption"
            >
            <template v-slot:first>
              <option :value="null">-- 選択してください。 --</option>
            </template>
          </b-form-select>
        </div>
        <div
          class="error-text-color bold"
          v-if="isChangeUser">
          「{{selectedMarkerName}}」ユーザーで選考評価を入力中です。
        </div>
      </div>

      <div id="serch-wrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search-area" >
          <div class="flex flexCenter">
            <div class="search-middle inlineBlock search-space">
              <div>年度</div>
              <div>
                <b-form-select
                  :value="filter.year"
                  @input="setFilter('year', $event)"
                  :options="yearList">
                </b-form-select>
              </div>
            </div>

            <div class="search-middle-long inlineBlock search-space">
              <div>助成種別</div>
              <div>
                <b-form-select
                  :value="filter.apptype"
                  @input="setFilter('apptype', $event);fetchAppList()"
                  :options.sync="apptypeList">
                  <template v-slot:first>
                    <option :value="null">-- 未選択 --</option>
                  </template>
                </b-form-select>
              </div>
            </div>

            <div class="search-middle-long inlineBlock search-space">
              <div>選考段階</div>
              <div>
                <b-form-select
                  @input="setFilter('stage', $event);fetchAppList()"
                  :value="filter.stage"
                  :options.sync="stageList">
                  <template v-slot:first>
                    <option :value="null">
                      <span v-if="stageList.length > 0">-- 未選択 --</span>
                      <span v-if="stageList.length <= 0 && !filter.apptype">
                        助成種別を選択してください</span>
                      <span v-if="stageList.length <= 0 && filter.apptype">
                        選考段階が登録されていません</span>
                    </option>
                  </template>
                </b-form-select>
              </div>
            </div>

            <div class="search-short inlineBlock search-space">
              <div>選考状況</div>
              <div>
                <b-form-select
                  @input="setFilter('status', $event)"
                  :value="filter.status"
                  :options="statusList">
                  <template v-slot:first>
                    <option :value="null">-- 未選択 --</option>
                  </template>
                </b-form-select>
              </div>
            </div>
          </div>

          <div class="flex ml-2 mt-2">
            <div>
              <b-form-checkbox
                v-if="isShowAll"
                class="inlineBlock mr-5"
                v-model="filter.all"
                @input="switchFailureAll($event, 'failure');fetchAppList()"
                switches
              >
                すべての申請を表示する
              </b-form-checkbox>
              <b-form-checkbox
                class="inlineBlock"
                v-if="hasPrestage"
                v-model="filter.failure"
                @input="switchFailureAll($event, 'all');fetchAppList()"
                switches
              >
                不合格になった申請のみを表示する
              </b-form-checkbox>
            </div>
          </div>
          <div class="flex ml-2 mt-2">
            <b-link
              v-b-modal.SettingListModal
              >一覧に表示する項目を変更する</b-link>
          </div>
        </div>
      </div>

      <div
        class="contentsWidth mx-auto err-wrap"
        v-if="!inTerm">
        <p class="mb-0 error-text-color text-center">選考評価期間外のため、評価の入力はできません。</p>
      </div>

      <!-- スコアボード -->
      <div
        v-if="Object.keys(scoreBoard).length > 0">
        <div class="bold">総合評価の内訳</div>
        <table class="b-table table-striped table-hover">
          <thead class="tableHead">
            <tr>
              <th
                v-for="(board, index, key) in scoreBoard" :key="`sb_th_${index}`"
                class="scoreboard-item pl-2">
                {{key}}点
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                v-for="(board, index) in scoreBoard" :key="`sb_td_${index}`"
                class="scoreboard-item pl-2">
                <span v-if="!isNaN(board.rate)">{{board.rate}}% ({{board.count}}件)</span>
                <span v-else>{{board.count}}件</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="evalTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          id='evalTable'
          table-class="table"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          show-empty
          :fields="header"
          :items="appList"
          :filter="filter"
          :filter-function="filtering"
          @filtered="onFiltered"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-null-last="true"
          :sort-compare="sortCompare"
          @sort-changed="onSortChanged"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">{{noDataMsg}}</div>
          </template>
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1">選択</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 link" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 link" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 link" @click="allSelect">全選択</p>
                <p class="mb-0 link" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="updateSelectedAppIdList($event, row.item.app_id)"
                :checked="selectedAppIdList"
                :value="row.item.app_id"
                />
            </div>
          </template>
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(year)="row">
            {{rawApptypeData[row.item.apptype_id].year}}
          </template>
          <template v-slot:cell(apptype)="row">
            {{rawApptypeData[row.item.apptype_id].name}}
          </template>
          <template v-slot:cell(stage)="row">
            <span
              v-show="!ignore">
              {{row.item.stage}}次
            </span>
          </template>
          <template v-slot:cell(score)="row" v-show="!ignore">
            <span>
              {{score_text(row.item.score)}}
            </span>
          </template>
          <!-- ステータス -->
          <template v-slot:cell(status)="row" v-show="!ignore">
            <b-link
              v-if="inTerm"
              @click="openPopup(row.item.app_id)">
              {{status_text(row.item.status)}}
            </b-link>
            <span
              v-else>
              {{status_text(row.item.status)}}
            </span>
          </template>
          <template v-slot:cell(btn)="row">
            <b-link
              v-show="!ignore"
              :to="`/cms/evaluation/edit/${row.item.app_id}/${row.item.stage_id}`">
              詳細
            </b-link>
            <b-link
              v-show="ignore"
              :to="`/cms/evaluation/view/failure/${row.item.app_id}/${row.item.stage_id}`">
              閲覧
            </b-link>
          </template>
        </b-table>
      </div>


      <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
        <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="!canExportPdf"
          @click="exportPdf()"
          >PDF出力</b-button>
          <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2 file-download-btn"
          :disabled="!canDownloadAppFiles"
          @click="downloadAppFiles()"
          >申請ファイル一括DL</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="!canExportCsv"
          @click="exportCsv()"
          >全件CSV出力</b-button>
      </div>
    </div>


    <b-modal
      id="evaluationModal"
      title-class="titleMsg"
      body-class="modalBox"
      content-class="p-2"
      title=""
      size="xl"
      ok-title=""
      ok-variant="secondary"
      :hide-header="true"
      :hide-footer="true"
      :centered="true"
      :no-close-on-backdrop="true"
    >
      <div
        v-if="popupAppId && Object.keys(popupForm).length>0"
        class="my-3 mx-auto">
        <div
          class="evaluation-item-wrap flex flex-between">
          <div
            v-for="(item, index) in evalItems[1]" :key="`${index}_1`"
            class="evaluation-item">
            <div>{{item.label}}</div>
            <div>
              <b-form-select
                v-model="popupForm[item.item_id].evaluation"
                :options="itemOption(item.min_param, item.max_param)"
                >
                  <template v-slot:first>
                    <option :value="null">-- 未選択 --</option>
                  </template>
              </b-form-select>
            </div>
          </div>
          <div
            v-for="(item, index) in evalItems[2]" :key="`${index}_2`"
            class="evaluation-item">
            <div>{{item.label}}</div>
            <div>
              <b-form-select
                v-model="popupForm[item.item_id].evaluation"
                :options="itemOption(item.min_param, item.max_param)"
                >
                  <template v-slot:first>
                    <option :value="null">-- 未選択 --</option>
                  </template>
              </b-form-select>
            </div>
          </div>
        </div>

        <div class="evaluation-item-wrap flex flex-between">
          <div
            v-for="(item, index) in evalItems[3]" :key="`${index}_3`"
            class="mt-2 w-100">
            <span>{{item.label}}</span>
            <b-form-textarea
              v-model="popupForm[item.item_id].comment"
              rows="3"
              max-rows="6"
              maxlength="800"
              placeholder="入力してください。"
              />
          </div>
        </div>
        <div
          class="error-text-color bold flex flexCenter"
          v-if="isChangeUser">
          「{{selectedMarkerName}}」ユーザーで選考評価を入力中です。
        </div>
        <div class="mt-3 flex flexCenter mx-auto">
          <b-button
            @click="saveEval"
            variant="primary"
            class="btn btn-primary btn-lg bold"
            >登録</b-button>
          <b-button
            @click="$bvModal.hide('evaluationModal')"
            class="btn btn-primary btn-lg bold ml-4"
            >キャンセル</b-button>
        </div>
      </div>
    </b-modal>

    <v-setting-list-modal></v-setting-list-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { cloneDeep } from 'lodash';
import api from '@/modules/api';
import download from '@/modules/download';
import CONST_AUTH from '@/constants/auth';
import SettingListModal from '@/components/cms/evaluation/SettingListModal.vue';

export default {
  name: 'CmsApplicationsEvaluationList',
  components: {
    'v-setting-list-modal': SettingListModal,
  },
  data() {
    return {
      statusList: [
        { value: 0, text: '未' },
        { value: 1, text: '済' },
      ],
      yearList: [],
      rawApptypeData: {},
      appList: [],
      isInit: true,
      oldPage: 1,
      popupAppId: null,
      popupStageId: null,
      popupForm: {},
      // 選考評価担当一覧
      dbMarkerList: [],
      rowStageList: [],
      sortBy: null,
      sortDesc: false,
      tableShowData: [],
      seriesId: null,
    };
  },
  methods: {
    onSortChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.setSortedData();
    },
    setSortedData() {
      if (this.sortBy) {
        const sortedData = this.tableShowData.slice().sort((a, b) => {
          const aValue = a[this.sortBy];
          const bValue = b[this.sortBy];
          let result;

          if (aValue === null && bValue === null) {
            result = 0;
          } else if (aValue === null) {
            result = 1; // nullを常に最後に
          } else if (bValue === null) {
            result = -1; // nullを常に最後に
          } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            result = aValue - bValue;
          } else {
            result = aValue.toString().localeCompare(bValue.toString(), undefined, { numeric: true, sensitivity: 'base' });
          }
          return this.sortDesc ? -result : result;
        });
        this.$store.commit('cmsEvaluationSearch/setFilterdIdsList', sortedData);
      }
    },
    async initFetch() {
      const param = {
        year: this.filter.year,
        apptype: this.filter.apptype,
        stage: this.filter.stage,
        failure: this.filter.failure,
        all: this.filter.all,
        marker: this.filter.marker,
      };
      const promiseFuncs = [
        api.send('/api/cms/evaluation/list', param),
      ];
      if (this.hasSelect) {
        promiseFuncs.push(api.send('/api/cms/evaluation/applications/list', param));
      }
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.log(err);
        });
      if (!responses) {
        return;
      }
      this.yearList = responses[0].data.yearList;
      // 年度がすでに選択されていなければ、最新の年度を選択状態にする
      if (this.filter.year === null && Object.keys(this.yearList).length > 0) {
        const yearParam = { key: 'year', value: this.yearList[0] };
        this.$store.commit('cmsEvaluationSearch/setFilter', yearParam);
      }
      this.rawApptypeData = responses[0].data.apptypeData;
      if (this.hasSelect) {
        this.appList = responses[1].data.appList;
        this.seriesId = responses[1].data.seriesId;
      }
      this.dbMarkerList = responses[0].data.markerList;
    },
    async fetchAppList() {
      if (!this.hasSelect) {
        this.appList = [];
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        apptype: this.filter.apptype,
        stage: this.filter.stage,
        failure: this.filter.failure,
        all: this.isShowAll ? this.filter.all : false,
        marker: this.filter.marker,
      };
      const response = await api.send('/api/cms/evaluation/applications/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.$store.commit('cmsEvaluationSearch/setEvalItems', response.data.evalItems);
      this.$store.commit('cmsEvaluationSearch/makeAllHeaderItems');
      this.rowStageList = response.data.rawStageList;
      this.appList = this.setAppList(response.data.appList);
      this.seriesId = response.data.seriesId;
    },
    setAppList(dbAppList) {
      const appList = [];
      dbAppList.forEach((rowData) => {
        const data = rowData;
        if (data.eval) {
          Object.keys(data.eval).forEach((id) => {
            const item = data.eval[id];
            if (item.type === 1) {
              data[item.label] = item.evaluation;
            }
            if (item.type === 3) {
              data.comment = item.comment;
            }
          });
        }

        appList.push(data);
      });
      return appList;
    },
    filtering(lineData, filter) {
      const apptypeData = this.rawApptypeData[lineData.apptype_id];
      const okYear = apptypeData.year === Number(filter.year);
      const okApptype = filter.apptype === null || lineData.apptype_id === filter.apptype;
      const okStage = filter.stage === null || lineData.stage === filter.stage;
      const okStatus = filter.status === null || lineData.status === filter.status;
      return okYear && okApptype && (okStage || this.ignore) && (okStatus || this.ignore);
    },
    onFiltered(filteredItems) {
      this.$store.commit('cmsEvaluationSearch/setTotalRows', filteredItems.length);
      if (this.isInit) {
        this.$store.commit('cmsEvaluationSearch/setCurrentPage', this.oldPage);
      } else {
        this.$store.commit('cmsEvaluationSearch/setCurrentPage', 1);
      }
      this.$store.commit('cmsEvaluationSearch/initSelectedAppid');
      // 編集ページ内での次の申請、前の申請に移動するためのIDリストの保存
      this.$store.commit('cmsEvaluationSearch/setFilterdIdsList', filteredItems);
      this.tableShowData = filteredItems;
      this.setSortedData();
    },
    setFilter(key, value) {
      const param = { key, value };
      this.$store.commit('cmsEvaluationSearch/setFilter', param);
    },
    allSelect() {
      this.$store.commit('cmsEvaluationSearch/allSelectedAppid');
    },
    allRemove() {
      this.$store.commit('cmsEvaluationSearch/initSelectedAppid');
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdsList.slice(start, end);
      idListOnPage.forEach((ids) => {
        this.$store.commit('cmsEvaluationSearch/addSelectedAppid', ids.appId);
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdsList.slice(start, end);
      idListOnPage.forEach((ids) => {
        this.$store.commit('cmsEvaluationSearch/removeSelectedAppid', ids.appId);
      });
    },
    async exportPdf() {
      if (!this.canExportPdf) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        selectedAppIdList: this.selectedAppIdList,
        apptypeId: this.filter.apptype,
        year: this.filter.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/evaluation/list/export/pdf', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const fileName = `${ymd}_${this.rawApptypeData[this.filter.apptype].name}.pdf`;
      download.blob(response.data, fileName);
    },
    async downloadAppFiles() {
      if (!this.canDownloadAppFiles) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        selectedAppIdList: this.selectedAppIdList,
        seriesId: this.seriesId,
        year: this.filter.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/evaluation/download/app/files', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        // eslint-disable-next-line
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const fileName = `${ymd}_${this.rawApptypeData[this.filter.apptype].name}.zip`;
      download.blob(response.data, fileName);
    },
    updateSelectedAppIdList(event, appId) {
      if (!event) {
        this.$store.commit('cmsEvaluationSearch/removeSelectedAppid', appId);
      } else {
        const isChecked = event.find((id) => {
          return id === appId;
        });
        if (!isChecked) {
          this.$store.commit('cmsEvaluationSearch/removeSelectedAppid', appId);
        } else {
          this.$store.commit('cmsEvaluationSearch/addSelectedAppid', appId);
        }
      }
    },
    // 片方をONにするともう片方をオフにする
    switchFailureAll(event, reset) {
      if (event) {
        const param = { key: reset, value: false };
        this.$store.commit('cmsEvaluationSearch/setFilter', param);
      }
    },
    // eslint-disable-next-line
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      if (key === 'score') {
        if (sortDesc && aRow[key] === null) {
          return -1;
        }
        if (sortDesc && bRow[key] === null) {
          return +1;
        }
      }
      return false;
    },
    async exportCsv() {
      if (!this.canExportCsv) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        apptype: this.filter.apptype,
        stage: this.filter.stage,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/evaluation/list/export/csv', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const apptype = this.apptypeList.find(data => data.value === this.filter.apptype);
      const fileName = `${ymd}_${apptype.text}_${this.filter.stage}次.csv`;
      download.csvUtf(response.data, fileName);
    },
    setCurrentPage(page) {
      this.$store.commit('cmsEvaluationSearch/setCurrentPage', page);
    },
    setPerPage(page) {
      this.$store.commit('cmsEvaluationSearch/setPerPage', page);
    },
    setTotalRows(value) {
      this.$store.commit('cmsEvaluationSearch/setTotalRows', value);
    },
    openPopup(appId) {
      this.popupAppId = appId;
      const modalApp = this.appList.find((app) => {
        return app.app_id === appId;
      });
      if (modalApp.eval.length === 0) {
        this.popupForm = {};
        Object.keys(this.evalItems).forEach((type) => {
          Object.keys(this.evalItems[type]).forEach((index) => {
            const itemId = this.evalItems[type][index].item_id;
            const param = {
              item_id: itemId,
              type: this.evalItems[type][index].type,
              comment: null,
              evaluation: null,
            };
            this.$set(this.popupForm, itemId, param);
          });
        });
      } else {
        Object.keys(modalApp.eval).forEach((itemId) => {
          this.$set(this.popupForm, itemId, cloneDeep(modalApp.eval[itemId]));
        });
      }
      this.popupStageId = modalApp.stage_id;
      if (Object.keys(this.popupForm).length > 0) {
        this.$bvModal.show('evaluationModal');
      }
    },
    async saveEval() {
      const params = {
        appId: this.popupAppId,
        stageId: this.popupStageId,
        evalFormData: this.popupForm,
        marker: this.filter.marker,
      };
      this.$store.dispatch('page/onWaiting', false);
      const response = await api.send('/api/cms/evaluation/save', params)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('登録に失敗しました。', false);
        return;
      }
      await this.alert('登録しました。', false);
      this.fetchAppList();
      this.$bvModal.hide('evaluationModal');
    },
    async changeMarker(event) {
      const selectedMarker = this.dbMarkerList.find((marker) => {
        return marker.id === event;
      });
      if (typeof selectedMarker === 'undefined') {
        this.$store.commit('cmsEvaluationSearch/setSelectedMarkerName', null);
      } else {
        this.$store.commit('cmsEvaluationSearch/setSelectedMarkerName', selectedMarker.name);
      }
      this.setFilter('marker', event);
      await this.initFetch();
      await this.fetchAppList();
    },
  },
  computed: {
    filter() {
      return this.$store.state.cmsEvaluationSearch.filter;
    },
    currentPage() {
      return this.$store.state.cmsEvaluationSearch.currentPage;
    },
    perPage() {
      return this.$store.state.cmsEvaluationSearch.perPage;
    },
    totalRows() {
      return this.$store.state.cmsEvaluationSearch.totalRows;
    },
    evalItems() {
      return this.$store.state.cmsEvaluationSearch.evalItems;
    },
    apptypeList() {
      const apptypeList = [];
      Object.keys(this.rawApptypeData).forEach((key) => {
        if (this.rawApptypeData[key].year === Number(this.filter.year)) {
          const param = {
            value: this.rawApptypeData[key].id,
            text: this.rawApptypeData[key].name,
          };
          apptypeList.push(param);
        }
      });
      return apptypeList;
    },
    stageList() {
      const apptypeId = this.filter.apptype;
      if (apptypeId === null || !this.rawApptypeData[apptypeId]) {
        return [];
      }
      return this.rawApptypeData[apptypeId].evaluation_stages.map((stage) => {
        return {
          value: stage.stage,
          text: `${stage.stage}次`,
          from: stage.from,
          to: stage.to,
        };
      });
    },
    isShowAll() {
      const apptypeId = this.filter.apptype;
      if (apptypeId === null || !this.rawApptypeData[apptypeId]) {
        return true;
      }
      return this.rawApptypeData[apptypeId].isUseGroup === 0 || !this.isChangeUser;
    },
    hasSelect() {
      const okYear = this.filter.year !== null;
      const okApptype = this.filter.apptype !== null;
      const okStage = this.filter.stage !== null || this.filter.all;
      return okYear && okApptype && okStage;
    },
    hasPrestage() {
      let result = false;
      const searchResult = this.rowStageList.some((data) => {
        return this.filter.stage > data.stage;
      });
      result = searchResult || result;
      return result;
    },
    selectedItems() {
      return this.$store.state.cmsEvaluationSearch.selectedHeaderItems;
    },
    allHeaderItems() {
      return this.$store.state.cmsEvaluationSearch.allHeaderItems;
    },
    header() {
      const header = [];
      header.push({ key: 'checkbox', label: '', sortable: false });
      header.push({ key: 'code', label: '申請番号', sortable: true });
      header.push({ key: 'kana', label: '申請者名', sortable: true });
      this.allHeaderItems.forEach((item) => {
        if (this.selectedItems.includes(item.key)) {
          header.push(item);
        }
      });

      header.push({ key: 'status', label: '選考', sortable: true, class: 'short-th' });
      header.push({ key: 'btn', label: '', sortable: false, class: 'short-th' });
      return header;
    },
    pageOptions() {
      return [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ];
    },
    status_text() {
      return (status) => {
        if (this.ignore) {
          return '-';
        }
        return status === 1 ? '済' : '未';
      };
    },
    score_text() {
      return (score) => {
        if (this.ignore) {
          return '-';
        }
        return score;
      };
    },
    prepassList() {
      return [
        { value: 1, text: '合格' },
        { value: 0, text: '不合格' },
      ];
    },
    ignore() {
      return this.filter.failure || this.filter.all;
    },
    // 年度と助成種別の選択が必要
    canExportCsv() {
      return this.filter.apptype && this.filter.stage && !this.ignore;
    },
    canExportPdf() {
      const number = this.selectedAppIdList.length > 0;
      return number;
    },
    canDownloadAppFiles() {
      const { year, apptype } = this.filter;
      const number = this.selectedAppIdList.length > 0;
      return number && apptype && year !== null;
    },
    selectedAppIdList() {
      return this.$store.state.cmsEvaluationSearch.selectedAppIdList;
    },
    filterdIdsList() {
      return this.$store.state.cmsEvaluationSearch.filterdIdsList;
    },
    noDataMsg() {
      if (!this.hasSelect) {
        return '年度、助成種別、選考段階を選択してください。';
      }
      return '条件に一致するデータがありません。';
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    scoreBoard() {
      if (this.appList.length === 0) {
        return {};
      }
      let allCount = 0;
      const scoreBoard = {
        0: { count: 0, rate: 0 },
        1: { count: 0, rate: 0 },
        2: { count: 0, rate: 0 },
        3: { count: 0, rate: 0 },
      };
      this.appList.forEach((app) => {
        if (app.overall_score && typeof scoreBoard[app.overall_score] !== 'undefined') {
          allCount += 1;
          scoreBoard[app.overall_score].count += 1;
        }
      });
      Object.keys(scoreBoard).forEach((score) => {
        scoreBoard[score].rate = Math.round(scoreBoard[score].count / allCount * 100);
      });
      return scoreBoard;
    },
    itemOption() {
      return (min, max) => {
        const itemOption = [];
        for (let i = min; i <= max; i += 1) {
          itemOption.push({ value: i, text: i });
        }
        return itemOption;
      };
    },
    hasZaidanAuth() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.SECRETARIAT);
    },
    markerOption() {
      const markerList = [];
      this.dbMarkerList.forEach((marker) => {
        const data = {
          value: marker.id,
          text: `${marker.name}`,
        };
        markerList.push(data);
      });
      return markerList;
    },
    isChangeUser() {
      const loginUserId = this.$store.state.auth.user_id;
      return this.filter.marker !== null && this.filter.marker !== loginUserId;
    },
    selectedMarkerName() {
      return this.$store.state.cmsEvaluationSearch.selectedMarkerName;
    },
    inTerm() {
      if (Object.keys(this.rawApptypeData).length === 0 || this.filter.apptype === null || this.filter.stage === null) {
        return true;
      }
      const thisApptype = this.rawApptypeData[this.filter.apptype];
      if (!thisApptype.evaluation_stages || thisApptype.evaluation_stages.length === 0) {
        return true;
      }
      const thisStage = thisApptype.evaluation_stages.find((stage) => {
        return stage.stage === this.filter.stage;
      });
      if (!thisStage) {
        return true;
      }
      return moment().isBetween(thisStage.from, thisStage.to);
    },
  },
  watch: {
    apptypeList() {
      // リストが変更されたら、値を初期にする
      this.setFilter('apptype', null);
      // ただし、変更後に要素が１つしかなければ、その値をセットする
      if (this.apptypeList.length === 1) {
        this.setFilter('apptype', this.apptypeList[0].value);
      }
    },
    stageList() {
      // リストが変更されたら、値を初期にする
      this.setFilter('stage', null);
      // ただし、変更後に要素が１つしかなければ、その値をセットする
      if (this.stageList.length === 1) {
        this.setFilter('stage', this.stageList[0].value);
      } else if (this.stageList.length > 1) {
        // 複数ある場合は、現在期間中のものがあれば、最初に見つけたステージをセットする
        const now = moment();
        const nowStage = this.stageList.find((stage) => {
          return now.isBetween(moment(stage.from), moment(stage.to));
        });
        if (nowStage) {
          this.setFilter('stage', nowStage.value);
        }
      }
    },
    'filter.year': function (val) {
      if (val === null) {
        this.initFetch();
      }
    },
  },

  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.oldPage = this.currentPage;
    await this.initFetch();
    this.isInit = false;
    // ローカルストレージから、ヘッダー設定を取得する
    this.$store.commit('cmsEvaluationSearch/loadHeaderItems');
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  #evalTable {
    width: 1080px !important;
    max-width: 1080px !important;
  }
  #evalTable thead th:nth-of-type(1) {
    width: 60px !important;
  }
  #evalTable thead th:nth-of-type(2) {
    width: 130px !important;
    max-width: 130px !important;
  }
  #evalTable thead th:nth-of-type(3) {
    width: 140px !important;
  }
  #evalTable thead th.short-th {
    max-width: 100px !important;
  }
  #evalTable thead th.long-th {
    max-width: 290px !important;
  }
  #evalTable td.ellipsis-td {
    width: 290px !important;
    max-width: 290px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>

<style scoped>
  .btn-primary.file-download-btn {
    width: 250px;
  }
  #serch-wrap input, #serch-wrap select, .marker-wrap select {
    height: 50px;
  }

  .search-area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .search-short {
    width: 130px;
  }
  .search-middle {
    width: 290px;
  }

  .search-middle-long {
    width: 300px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .scoreboard-item {
    width: 140px !important;
  }

  .evaluation-item {
    margin: 0 5px;
  }
</style>
